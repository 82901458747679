import React, { FC } from "react"
import { Props } from "./types"
import * as styles from "./styles.module.scss"

const Switch: FC<Props> = ({ className = "", labels, name, onChange, tab }) => {
  return (
    <div className={`${className} ${styles.switch}`}>
      {labels.map((item, key) => {
        return (
          <label
            key={`${name}-${key}`}
            htmlFor={item}
            className={styles.switchLabel}
          >
            <input
              onChange={() => onChange?.(item)}
              type="radio"
              id={item}
              name={name}
              value={item}
              checked={tab === item}
            />
            <div
              className={`${styles.switchTab} ${
                tab === item ? styles.active : ""
              }`}
            >
              {item}
            </div>
          </label>
        )
      })}
      <div className={styles.switchBg} data-index={labels.indexOf(tab)} />
    </div>
  )
}

export default Switch
