import React, { FC, useState, useMemo } from "react"
import * as styles from "../styles.module.scss"
import { Props } from "./types"
import Img from "gatsby-image"
import Switch from "src/components/Switch"
import { motion, AnimatePresence } from "framer-motion"
import { wrap } from "@popmotion/popcorn"

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
}

const Feature: FC<Props> = ({ className, feature }) => {
  const tabs = useMemo(() => {
    return [feature.tab1, feature.tab2, feature.tab3]
  }, [feature])

  const [[page, direction], setPage] = useState([0, 0])

  const tabIndex = wrap(0, tabs.length, page)

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection])
  }

  function onSwitchChanges(newTab: string) {
    const newIndex = feature.switch.labels.indexOf(newTab)

    const direction = newIndex > tabIndex ? 1 : -1

    setPage([newIndex, direction])
  }

  return (
    <div className={`${styles.mainContainer} ${className}`} id={feature.anchor}>
      <div className={`content-wrapper ${styles.feature}`}>
        <span className={styles.label}>{feature.label}</span>
        <h2 className={styles.title}>{feature.title}</h2>
        <p className={styles.pagraph}>{feature.description}</p>

        <div
          style={{
            height: "100%",
            width: "100%",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{ height: "100%", width: "100%", opacity: 0 }}
            className={styles.imgContainer}
          >
            <Img fluid={tabs[tabIndex].img.childImageSharp.fluid} />
          </div>

          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              key={page}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 200 },
                opacity: { duration: 0.2 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(_, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x)

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1)
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1)
                }
              }}
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
              className={styles.imgContainer}
            >
              <div className={styles.imgOverlay} />
              <Img fluid={tabs[tabIndex].img.childImageSharp.fluid} />
            </motion.div>
          </AnimatePresence>
        </div>

        <Switch
          labels={feature.switch.labels}
          name={feature.switch.name}
          tab={feature.switch.labels[tabIndex]}
          onChange={onSwitchChanges}
          className={styles.switch}
        />
        <p className={styles.pagraph}>{tabs[tabIndex].text}</p>
        {feature.list && (
          <div className={styles.grid}>
            {feature.list.map((item: any, index: number) => (
              <div className={styles.item} key={`${item.name}-${index}`}>
                <div className={styles.iconContainer}>
                  <img src={require(`../icons/${item.icon}`)} />
                </div>
                <h3 className={styles.itemName}>{item.name}</h3>
                <p className={styles.itemDescription}>{item.description}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Feature

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity
}
