import { graphql } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import { Props } from "./types"
import { usePages } from "src/context/pages"
import Logo from "src/images/structured-data-logo.png"
import HeaderTeamsPage from "src/components/HeaderTeamsPage"
import * as styles from "./styles.module.scss"
import Feature from "src/templates/features/Feature"
import IntegrationCard from "src/components/IntegrationCard"
import Link from "src/utils/Link"
import Reviews from "src/components/Reviews"
import GetStarted from "src/components/Banners/GetStarted"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const { currentPath, getPage } = usePages()

  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
    >
      <HeaderTeamsPage
        title={texts.header}
        description={texts.description}
        button={true}
      />
      <div className={styles.main}>
        {texts.features.map((feature) => (
          <Feature feature={feature} key={feature.label} />
        ))}
        <div className={`${styles.integrations} content-wrapper`}>
          <span className={styles.label}>
            {texts.integrationsSection.label}
          </span>
          <h2 className={styles.title}>{texts.integrationsSection.title}</h2>
          <p className={styles.pagraph}>
            {texts.integrationsSection.description}
          </p>
          <div className={styles.integrationGrid}>
            {texts.integrationsSection.list.map((integration) => {
              return (
                <IntegrationCard
                  key={integration.name}
                  {...integration}
                  linkText={texts.integrationsSection.learnMore}
                  className={styles.integrationCard}
                />
              )
            })}
          </div>
          <Link
            to={getPage("integrations").url}
            className={`button-new button-new--primary`}
          >
            {texts.integrationsSection.buttonText}
          </Link>
        </div>
        <Reviews />
      </div>
      <div className={`content-wrapper ${styles.banner}`}>
        <GetStarted />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        description
        integrationsSection {
          label
          title
          description
          list {
            name
            description
            url
          }
          buttonText
          learnMore
        }
        features {
          label
          anchor
          title
          description
          switch {
            name
            labels
          }
          tab1 {
            img {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1084) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          tab2 {
            img {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1084) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          tab3 {
            img {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1084) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          list {
            name
            description
            icon
          }
        }
      }
    }
  }
`

export default Template
